exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-education-services-js": () => import("./../../../src/pages/educationServices.js" /* webpackChunkName: "component---src-pages-education-services-js" */),
  "component---src-pages-guardianship-js": () => import("./../../../src/pages/guardianship.js" /* webpackChunkName: "component---src-pages-guardianship-js" */),
  "component---src-pages-homestay-application-js": () => import("./../../../src/pages/homestay_application.js" /* webpackChunkName: "component---src-pages-homestay-application-js" */),
  "component---src-pages-homestay-js": () => import("./../../../src/pages/homestay.js" /* webpackChunkName: "component---src-pages-homestay-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policy-absent-and-missing-js": () => import("./../../../src/pages/policy/absent-and-missing.js" /* webpackChunkName: "component---src-pages-policy-absent-and-missing-js" */),
  "component---src-pages-policy-anti-bullying-js": () => import("./../../../src/pages/policy/anti-bullying.js" /* webpackChunkName: "component---src-pages-policy-anti-bullying-js" */),
  "component---src-pages-policy-child-protection-js": () => import("./../../../src/pages/policy/child-protection.js" /* webpackChunkName: "component---src-pages-policy-child-protection-js" */),
  "component---src-pages-policy-complaints-js": () => import("./../../../src/pages/policy/complaints.js" /* webpackChunkName: "component---src-pages-policy-complaints-js" */),
  "component---src-pages-policy-discipline-and-restraint-js": () => import("./../../../src/pages/policy/discipline-and-restraint.js" /* webpackChunkName: "component---src-pages-policy-discipline-and-restraint-js" */),
  "component---src-pages-policy-emergency-plan-js": () => import("./../../../src/pages/policy/emergency-plan.js" /* webpackChunkName: "component---src-pages-policy-emergency-plan-js" */),
  "component---src-pages-policy-prevent-js": () => import("./../../../src/pages/policy/prevent.js" /* webpackChunkName: "component---src-pages-policy-prevent-js" */),
  "component---src-pages-policy-privacy-js": () => import("./../../../src/pages/policy/privacy.js" /* webpackChunkName: "component---src-pages-policy-privacy-js" */),
  "component---src-pages-policy-safeguard-js": () => import("./../../../src/pages/policy/safeguard.js" /* webpackChunkName: "component---src-pages-policy-safeguard-js" */),
  "component---src-pages-policy-safeuse-internet-js": () => import("./../../../src/pages/policy/safeuse-internet.js" /* webpackChunkName: "component---src-pages-policy-safeuse-internet-js" */),
  "component---src-pages-policy-whistleblowing-js": () => import("./../../../src/pages/policy/whistleblowing.js" /* webpackChunkName: "component---src-pages-policy-whistleblowing-js" */),
  "component---src-pages-support-services-js": () => import("./../../../src/pages/supportServices.js" /* webpackChunkName: "component---src-pages-support-services-js" */),
  "component---src-pages-workwithus-js": () => import("./../../../src/pages/workwithus.js" /* webpackChunkName: "component---src-pages-workwithus-js" */),
  "component---src-templates-api-page-js": () => import("./../../../src/templates/api-page.js" /* webpackChunkName: "component---src-templates-api-page-js" */),
  "component---src-templates-blog-post-for-redirect-js": () => import("./../../../src/templates/blog-post-for-redirect.js" /* webpackChunkName: "component---src-templates-blog-post-for-redirect-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

